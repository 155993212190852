import { signIn, signOut, useSession } from "next-auth/react";
import { useEffect } from "react";
import { keycloakSessionLogOut } from "./keycloakSessionLogout";
import { refreshAccessToken } from "./useTokenRefresh";

export function AuthWrapper({ children }: { children: React.ReactNode }) {
  const { data: session, status } = useSession();

  useEffect(() => {
    // Handle token refresh errors by logging out and redirecting
    if (
      status === "authenticated" &&
      session?.error === "RefreshAccessTokenError"
    ) {
      keycloakSessionLogOut().then(() => {
        signOut({ redirect: false }).then(() => signIn("keycloak"));
      });
    } else if (status === "unauthenticated") {
      signIn("keycloak");
    }

    // Clear the interval on component unmount
  }, [status, session]);


  if (status === "loading") {
    return (
      <main>
        <div>
          <h2>Loading...</h2>
        </div>
      </main>
    );
  }

  if (status === "authenticated") {
    return <>{children}</>;
  }

  return null;
}
