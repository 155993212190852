import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Inter, Vazirmatn } from "next/font/google";
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";
import { AuthWrapper } from "@/_core/utils/authWrapper";

/* async function keycloakSessionLogOut() {
  try {
    await fetch(`/api/auth/logout`, { method: "GET" });
  } catch (err) {
    console.error(err);
  }
}
 */

const inter = Inter({
     subsets: ["latin"],
     weight: ["100", "300", "400", "500", "600", "700"],
     display: "swap", // Ensures fallback font is used until the custom font is loaded
});

const vazirmatn = Vazirmatn({
     subsets: ["arabic", "latin"],
     weight: ["400", "500", "600", "700", "800", "900"],
     display: "swap",
});

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
     const router = useRouter();

     useEffect(() => {
          // Store locale in local storage and set HTML lang and dir attributes
          if (router.locale) {
               localStorage.setItem("locale", router.locale);
               document.documentElement.setAttribute("lang", router.locale);
               document.documentElement.setAttribute("dir", router.locale === "fa" || router.locale === "ar" ? "rtl" : "ltr");
          }
     }, [router.locale]);

     const fontClassName = router.locale === "fa" || router.locale === "ar" ? vazirmatn.className : inter.className;

     return (
          <SessionProvider>
               <AuthWrapper>
                    <Component className={fontClassName} {...pageProps} />
               </AuthWrapper>
          </SessionProvider>
     );
};

export default appWithTranslation(MyApp);
